export function formatDate (date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  const o = {
    'Y': date.getFullYear(),
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
    }
  }
  return fmt
}

function padLeftZero (str) {
  return ('00' + str).substr(str.length)
}


/**
 * 获取指定日期范围内的所有日期
 *
 * @param {Date} startDateTimer - 起始日期
 * @param {Date} endDateTimer - 结束日期
 * @returns {string[]} - 日期范围内的所有日期的字符串表示，格式为 'yyyy-MM-dd'
 */
export function getAllDatesInRange (startDateTimer, endDateTimer) {

  const startDate = new Date(startDateTimer)
  const endDate = new Date(endDateTimer)
  const dates = []
  for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
    dates.push(formatDate(date, 'yyyy-MM-dd'))
  }
  return dates
}