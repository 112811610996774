<template>
  <div class="view-root flex-column">
    <p class="common-title">成员列表</p>
    <div style="text-align: right">
      <el-button type="primary" size="small" @click="addGuest"
        >添加嘉宾</el-button
      >
    </div>
    <div class="table-margin-top flex-1-overflow-y">
      <div class="table-header table-margin-bottom">
        <div class="table-index">序号</div>
        <div class="table-guest-name">嘉宾姓名</div>
        <div class="table-guest-role">嘉宾角色</div>
        <div class="table-guest-titles">头衔</div>
        <div class="table-company">所在公司</div>
        <div class="table-guest-avatar">头像</div>
        <div class="table-guest-session">参加的日程</div>
        <div class="table-operation">操作</div>
      </div>
      <div
        v-for="(guest, index) in guests"
        :key="index"
        class="table-item table-margin-bottom"
      >
        <div class="table-index">{{ index + 1 }}</div>
        <div class="table-guest-name" style="font-weight: bold">
          {{ guest.name }}
        </div>
        <div class="table-guest-role" style="font-weight: bold">
          {{ guest.role }}
        </div>
        <div
          class="table-guest-titles"
          style="display: flex; flex-wrap: wrap; gap: 5px"
        >
          <div
            v-for="(title, titleIndex) in guest.titles"
            :key="titleIndex"
            class="table-tag"
          >
            {{ title }}
          </div>
        </div>
        <div class="table-company" style="font-weight: bold">
          {{ guest.company }}
        </div>
        <div class="table-guest-avatar">
          <img
            :src="guest.avatar_url"
            style="
              width: 72px;
              height: 72px;
              object-fit: cover;
              border-radius: 50%;
            "
          />
        </div>
        <div class="table-guest-session" style="font-weight: bold">
          <div
            v-for="(session, sessionIndex) in guest.sessions"
            :key="sessionIndex"
          >
            {{ session.name }} {{ formatShowDate(session.date) }}
          </div>
        </div>
        <div class="table-operation" style="display: flex; align-items: center">
          <p
            class="btn-wrap-width"
            style="white-space: nowrap"
            @click="editGuestInfo(guest)"
          >
            编辑嘉宾信息
          </p>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="showGuestDialog" title="编辑嘉宾">
      <div>
        <el-form :model="guestInfo" :rules="rules" ref="guestInfo">
          <el-form-item label="嘉宾姓名" prop="name">
            <el-input v-model="guestInfo.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="头像">
            <el-upload
              class="avatar-uploader"
              action="/"
              :show-file-list="false"
              :before-upload="(file) => beforeAvatarUpload(file)"
            >
              <img
                v-if="guestInfo.avatar_url"
                :src="guestInfo.avatar_url"
                style="object-fit: cover"
                class="el-icon-plus avatar-uploader-icon"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="嘉宾角色" prop="role">
            <el-select v-model="guestInfo.role" placeholder="请选择">
              <el-option label="主持人" value="主持人"></el-option>
              <el-option label="演讲人" value="演讲人"></el-option>
              <el-option label="分享人" value="分享人"></el-option>
              <el-option label="致辞人" value="致辞人"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="">
            <span>头衔</span>
            <vue-tags-input
              v-model="tag"
              :tags="tags"
              placeholder="请输入"
              @tags-changed="(newTags) => (tags = newTags)"
            />
          </el-form-item>
          <el-form-item label="所在公司" prop="">
            <el-select
              v-model="guestInfo.company"
              placeholder="请选择"
              style="width: 400px"
            >
              <el-option
                v-for="(companyName, index) in companyArr"
                :key="index"
                :label="companyName.name"
                :value="companyName.name"
              ></el-option>
            </el-select>
            <!-- <el-input v-model="guestInfo.company" placeholder="请输入"></el-input> -->
          </el-form-item>
          <el-form-item label="">
            <label>单位介绍</label>
            <quill-editor
              class="editor"
              style="margin-bottom: 20px"
              ref="myTextEditor"
              v-model="guestInfo.company_intro"
              :options="editorOption"
            >
            </quill-editor>
          </el-form-item>

          <el-form-item label="">
            <label>简介</label>
            <quill-editor
              class="editor"
              style="margin-bottom: 20px"
              ref="myTextEditor"
              v-model="guestInfo.intro"
              :options="editorOption"
            >
            </quill-editor>
          </el-form-item>
          <el-form-item label="登录账号">
            <el-input v-model="guestInfo.email" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input
              v-model="guestInfo.password"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: right">
          <span slot="footer" class="dialog-footer">
            <el-button @click="showGuestDialog = false">取 消</el-button>
            <el-button type="primary" @click="saveGuestInfo()">确 定</el-button>
          </span>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showGuestDialog1" title="选择嘉宾" width="75%">
      <el-button @click="editGuestInfo()" type="primary">新增嘉宾</el-button>
      <div style="margin-top: 10px">
        <el-select v-model="session_id" placeholder="请选择日程">
          <el-option
            v-for="(session, index) in sessions"
            :key="index"
            :label="session.name"
            :value="session._id.$id"
          ></el-option>
        </el-select>
      </div>
      <div style="margin-top: 10px">
        <el-select v-model="guest_id" placeholder="请选择嘉宾">
          <el-option
            v-for="(guest, index) in allGuests"
            :key="index"
            :label="guest.name"
            :value="guest._id.$id"
          ></el-option>
        </el-select>
      </div>
      <div style="margin-top: 10px">
        <el-button @click="confirmAddGuest" type="primary">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMeetingGuests,
  updateGuest,
  getQiniuToken,
  getbrandPartys,
  getGuests,
  addMeetingGuest,
  getSessions,
  removeMeetingGuest,
} from "../../api/api";
import { formatDate } from "../../utils/date";
import VueTagsInput from "@johmun/vue-tags-input";
import "../../assets/common/common.css";
export default {
  components: {
    VueTagsInput,
  },
  data() {
    return {
      guests: [],
      meeting_id: this.$route.query.meeting_id,
      meeting_name: this.$route.query.meeting_name,
      showGuestDialog: false,
      showGuestDialog1: false,
      guestInfo: {
        id: "",
        name: "",
        company: "",
        intro: "",
        avatar_url: "",
        role: "",
        company_intro: "",
        email: "",
        password: "",
      },
      tag: "",
      tags: [],
      rules: {
        role: [{ required: true, message: "Please input", trigger: "blur" }],
        name: [{ required: true, message: "Please input", trigger: "blur" }],
        title: [{ required: true, message: "Please input", trigger: "blur" }],
        company: [{ required: true, message: "Please input", trigger: "blur" }],
        intro: [{ required: true, message: "Please input", trigger: "blur" }],
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ], // 工具菜单栏配置
        },
        placeholder: "", // 提示
        readyOnly: false, // 是否只读
        theme: "snow", // 主题 snow/bubble
        syntax: true, // 语法检测
      },
      companyArr: [],
      allGuests: [],
      sessions: [],
      session_id: "",
      guest_id: "",
    };
  },
  mounted() {
    this.fetchData();
    this.requestAllGuests();
    this.getSessions();
  },
  methods: {
    remove(guest, session) {
      removeMeetingGuest({
        session_id: session._id.$id,
        guest_id: guest._id.$id,
      }).then((res) => {
        this.$message.success("移除成功");
        this.fetchData();
      });
    },
    getSessions() {
      getSessions(this.meeting_id).then((res) => {
        this.sessions = res.data.data;
      });
    },
    addGuest() {
      this.session_id = "";
      this.guest_id = "";
      this.showGuestDialog1 = true;
    },
    confirmAddGuest() {
      addMeetingGuest({
        session_id: this.session_id,
        guest_id: this.guest_id,
      }).then((res) => {
        this.$message.success("添加成功");
        this.showGuestDialog1 = false;
        this.fetchData();
      });
    },
    requestAllGuests() {
      getGuests().then((res) => {
        this.allGuests = res.data.data;
      });
    },
    formatShowDate(time) {
      return formatDate(new Date(time * 1000), "yyyy-MM-dd");
    },
    fetchData() {
      getMeetingGuests({
        meeting_id: this.meeting_id,
      }).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          let arr = [];
          for (let i in res.data.data) {
            let guest = res.data.data[i].guest_info;
            guest.sessions = res.data.data[i].sessions;
            arr.push(guest);
          }
          this.guests = arr;
        }
      });

      getbrandPartys().then((res) => {
        this.companyArr = res.data.data;
      });
    },
    async beforeAvatarUpload(file) {
      const result = await getQiniuToken();
      if (result.data.code === 0) {
        const token = result.data.data;
        this.observable = qiniu.upload(
          file,
          new Date().getTime() + file.name,
          token
        );
        const observer = {
          complete: (res) => {
            this.guestInfo.avatar_url =
              "http://mainbucket.reachable-edu.com/" + res.key;
            console.log("url:" + this.guestInfo.avatar_url);
          },
        };
        this.observable.subscribe(observer);
      }
    },
    saveGuestInfo() {
      var titles = [];
      for (var i = 0; i < this.tags.length; i++) {
        titles.push(this.tags[i].text);
      }
      if (titles.length <= 0) {
        this.$message.error("请填写头衔");
        return;
      }
      this.guestInfo.titles = titles;
      updateGuest(this.guestInfo).then((res) => {
        this.$message.success("保存成功");
        this.fetchData();
        this.requestAllGuests();
        this.showGuestDialog = false;
      });
    },
    editGuestInfo(row) {
      this.tag = "";
      this.tags = [];
      if (row) {
        this.guestInfo = {
          id: row._id.$id,
          // session_id: row.session_id,
          name: row.name,
          company: row.company,
          intro: row.intro,
          avatar_url: row.avatar_url,
          role: row.role,
          company_intro: row.company_intro,
          email: row.email,
          password: row.password,
        };
        if (row.titles) {
          var temp_tags = row.titles;
          for (var i = 0; i < temp_tags.length; i++) {
            var tag = { text: temp_tags[i] };
            this.tags.push(tag);
          }
        }
      } else {
        this.guestInfo = {
          id: "",
          name: "",
          company: "",
          intro: "",
          // session_id: this.session_id,
          avatar_url: "",
          role: "",
          company_intro: "",
          email: "",
          password: "",
        };
      }
      this.showGuestDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.meetingGuestContainer {
  padding: 20px;
}
</style>
<style>
.table-guest-name {
  flex: 2;
}

.table-guest-role {
  flex: 2;
}

.table-guest-titles {
  flex: 3;
  padding: 0px 5px;
  box-sizing: border-box;
}

.table-company {
  flex: 3;
}

.table-guest-avatar {
  flex: 2;
}

.table-guest-session {
  flex: 5;
  padding: 0px 5px;
}

.table-operation {
  flex: 2;
  text-align: center;
}

.ql-container {
  height: 160px !important;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>